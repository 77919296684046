export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },

  // supplier
  {
    title: 'Suppliers',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'List suppliers',
        route: 'suppliers',
      },
      {
        title: 'Add supplier',
        route: 'create_supplier',
      },
    ],
  },
  // customer
  {
    title: 'Customers',
    icon: 'UserIcon',
    children: [
      {
        title: 'List customers',
        route: 'customers',
      },
      {
        title: 'Add customer',
        route: 'create_customer',
      },
    ],
  },
  // product
  {
    title: 'Products',
    icon: 'CoffeeIcon',
    children: [
      {
        title: 'List products',
        route: 'products',
      },
      {
        title: 'Add product',
        route: 'create_product',
      },
    ],
  },
  // stock
  {
    title: 'Stock',
    icon: 'PackageIcon',
    children: [
      {
        title: 'In way to Tunisia',
        route: 'in-way-to-tunisia',
      },
      {
        title: 'Received in Tunisia',
        route: 'received-in-tunisia',
      },
      {
        title: 'In way to Brazil',
        route: 'in-way-to-brazil',
      },
      {
        title: 'Received in Brazil',
        route: 'received-in-brazil',
      },
    ],
  },
  // order
  {
    title: 'Orders',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'List orders',
        route: 'orders',
      },
      {
        title: 'Add order',
        route: 'create_order',
      },
    ],
  },

  // invoices
  {
    title: 'Purchase Invoices',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List invoices',
        route: 'purchase_invoices',
      },
      {
        title: 'Add invoice',
        route: 'create_purchase_invoices_man',
      },
    ],
  },
  // delivery note
  {
    title: 'Delivery Notes',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'List Delivery Notes',
        route: 'delivery-notes',
      },
      {
        title: 'Add Delivery Note',
        route: 'create-delivery-note',
      },
    ],
  },
  // invoices
  {
    title: 'Invoices',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List invoices',
        route: 'invoices',
      },
      {
        title: 'Add invoice',
        route: 'create_invoices_man',
      },
    ],
  },
]
